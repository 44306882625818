const housingsModule = () => import(/* webpackChunkName: 'components/housings' */'../../components/housings/housings');
const imgGridModule = () => import(/* webpackChunkName: 'components/imgGrid' */'../../components/imgGrid/imgGrid');
const littleGenericEncartModule = () => import(/* webpackChunkName: 'components/littleGenericEncart' */'../../components/littleGenericEncart/littleGenericEncart');
const newsModule = () => import(/* webpackChunkName: 'components/news' */'../../components/news/news');
const pixleeModule = () => import(/* webpackChunkName: 'components/pixlee' */'../../components/pixlee/pixlee');
const comparePopinModule = () => import(/* webpackChunkName: 'components/comparePopin' */'../../components/comparePopin/comparePopin');
const popinGenericModule = () => import(/* webpackChunkName: 'components/popinGeneric/popinGeneric' */'../../components/popinGeneric/popinGeneric');

import '../../components/xp/xp';
import '../../components/cpBusiness/cpBusiness';

$(() => {
  housingsModule().then(({housings}) => {
    housings.init();
    housings.getHousingData();
  });

  imgGridModule().then(({imgGrid}) => {
    imgGrid.init();
  });

  littleGenericEncartModule().then(({littleGenericEncart}) => {
    littleGenericEncart.init();
  });

  newsModule().then(({news}) => {
    news.init();
  });

  pixleeModule().then(({pixlee}) => {
    pixlee.initialize();
  });

  comparePopinModule().then(({ComparePopin}) => {
    const comparePopin = new ComparePopin();
  });

  if (!DEVICE.isDesktop && sessionStorage.getItem('basketDatas') === null) {
    $('.submenu-navBooking--button').show();
  }

  popinGenericModule().then(({popinSurroundings, popinActivity}) => {
    popinActivity.init();
    popinSurroundings.init();
  });

  //Toggle desktop / mobile overview surroundings, twig isDesktop() returns tablets as desktop too
  $('#overviewSurroundings') && ($('#surroundingsSwiper').toggleClass('u-Hidden--forced', !DEVICE.isDesktop), $('.domainSurroundings-blockPictures').toggleClass('u-Hidden--forced', DEVICE.isDesktop));

  if (!$('#tab_' + GLOBAL_SCRIPTS.UNIVERS.toLowerCase() + '_surroundings').length) {
    $('#overviewSurroundings').addClass('u-Hidden--forced');
  } else {
    $('.js-showSurroundings').attr('href', `${GLOBAL_SCRIPTS.URL_CURRENT}/${PRODUCT_SCRIPTS.WORDING_PRODUCT.tab_cpe_surroundings.toLowerCase().replace(/\s+/g, '-')}`);
  }
});